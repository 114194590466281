<template>
    <div class="w-full h-auto px-3 py-3">
        <Header />
        <template>
            <h1 class="text-2xl font-medium">Home</h1>

            <h3 class="font-medium">Usuario</h3>
        </template>
    </div>
</template>

<script>
    import { state } from '@/store';
    import Header from '@/components/header/Header';

    export default {
        name: 'Home',
        components: { Header },
        computed: {
            user() {
                return state.user;
            },
        },
        mounted() {
            this.$router.push({ name: 'Ventas' }).catch(() => ({}));
        },
    };
</script>
